// @flow
import React from "react";
import ListItems from "../../page-components/artists/items";
import { Grid, Layout, Heading } from "../../components";

import PropTypes from "prop-types";

const { List } = Grid;

const Component = () => {
  return (
    <Layout>
      <section id="gallery-list-section">
        <List  gridStyle={2}>
          <ListItems limit={200} attribute="artist" />
        </List>
      </section>
    </Layout>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;

//flow
import React from "react";
import PropTypes from "prop-types";

const Component = ({value,onChange}) => {
  return (
    <select  className="form-control margin-top-60" name="option" onChange={onChange}>
      <option  value={null} selected={value===null}>
        Sort By
      </option>
      <option selected={value==="firstname"}  value="firstname"> Firstname</option>
      <option selected={value==="lastname"} value="lastname">Lastname</option>
    </select>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;

// @flow
import React, { useState } from "react";
import { compose, withHandlers, withState } from "recompose";
import { connectMenu } from "react-instantsearch-dom";
import Heading from "../../components/heading";
import { withAlgoliaConnect } from "../../HOC";
import { prepareArtistNames } from "../../util";
import { sortBy } from "lodash";
import { ArtistList, Search, SortBy,ToggleView } from "./components";

// const prepareItems = (items: Array<{ value: string }>) => {
//   return items.map(item => ({
//     ...prepareArtistNames(item.value),
//     value: item.value
//   }));
// };

const Component = ({ items, searchForItems }) => {
  const [_items, setItems] = useState([]);
  const [sortValue, setSort] = useState(null);
  const [isList, setIsList] = useState(false);

  items &&
    items.forEach((item, indx) => {
      items[indx] = { ...item, ...prepareArtistNames(item.value) };
    });

  const search = e => {
    setItems([]);
    setSort(null)
    const query = e && e.target && e.target.value;
    searchForItems && searchForItems(query);
    //setItems([]);
  };

  const onChange = e => {
    const sortField = e.target.value;
    setSort(sortField)
    if (sortField) {
      const sorted = sortBy(items, sortField);
      setItems(sorted);
    }
  };

  const onColumnView=()=>{
    setIsList(false)
  }

  const onListView=()=>{
    setIsList(true)
  }

  return (
    <div>
      <section className="row tt-wrap ">
        <div className="col-sm-2">
          <Heading heading="Artists" subtitle="As popularly known" />
        </div>
        <div className="col-sm-4 hidden-print">
          <Search onChange={search} />
        </div>
        <div className="col-sm-4  hidden-print ">
          <SortBy value={sortValue} onChange={onChange} />
        </div>
        <div className="text-center col-sm-2 hidden-print">
          <ToggleView isList={isList} onListView={onListView} onColumnView={onColumnView}/>
        </div>
      </section>
      <section className="margin-top-40">
        {_items.length > 1 ? (
          <ArtistList  isList={isList} items={_items} />
        ) : (
          <ArtistList isList={isList} items={items} />
        )}
      </section>
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default compose(
  withAlgoliaConnect(process.env.ALG_INDEX_NAME),
  connectMenu
)(Component);

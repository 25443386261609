//flow
import React from "react";
import PropTypes from "prop-types";

type Type={
  onChange:Function
}

const Component = ({onChange}:Type) => {
  return (
    <input
      className="form-control margin-top-60 "
      type="search"
      placeholder={"Search For Artists By Name"}
      onChange={onChange}
    />
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;

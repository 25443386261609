//flow
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {chunk} from 'lodash'
import qs from "qs";
import { Link } from "gatsby";
import classnames from "classnames"

import * as Grid from "../../../components/grid";
const { ListItem, List } = Grid;

const prepareQuery = (item: { value: string }) =>
  `?${qs.stringify({ page: 1, refinementList: { artist: [item.value] } })}`;

type Type = {
  items: Array<any>,
  isList: boolean
};
const Component = ({ items, isList }: Type) => {
  const chunked = isList? chunk(items,35):chunk(items,1);
  return chunked.map((chunked,indx)=>(
    <span key={indx} className={classnames({"col-lg-3 col-sm-12":isList})}>
      {chunked.map((item, key) => {
      const url = `/collection${prepareQuery(item)}`;
      return !isList ? (
       <Fragment>
         <div className="hidden-print">
        <ListItem
          showDetail
          url={url}
          linkState={{ description: `Works by ${item.value} ` }}
          src="./img/default-avatar.jpg"
          key={key}
          subtitle={item.value}
          itemStyle={0}
        />
         </div>
         <span className="print-only">
            {item.label}({item.count})
          </span>
       </Fragment>
      ) : (
        <Fragment>
        <Link className="hidden-print"
          key={key}
          to={url}
          state={{ linkState: { description: `Works by ${item.value} ` } }}
        >
          <span className='display-block'>{item.label}({item.count})</span>
        </Link>
          <span className="col-lg-3 col-sm-12 print-only">
            {item.label}({item.count})
          </span>
        </Fragment>
      );
    })}
    </span>
  ));
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;

//flow
import React, { Fragment } from "react";
import PropTypes from "prop-types";

type Type = {
  onListView: Function,
  onColumnView: Function
};

const Component = ({ onListView, onColumnView, isList }) => {
  const onPrint =()=>{
    if(typeof window !== "undefined")
    {
      window.print()
    }
  }
  return (
    <div className=" margin-top-70">
      <span className="margin-right-10-p">
        {!isList ? (
          <i className="fa fa-columns" />
        ) : (
          <a href='#' onClick={onColumnView}>
            <i className="fa fa-columns" />
          </a>
        )}
      </span>
      <span className="margin-right-10-p" >
        {isList ? (
          <i className="fa fa-list" />
        ) : (
          <a href="#" onClick={onListView}>
            <i className="fa fa-list" />
          </a>
        )}
      </span>
      <span >
          <a href="#" onClick={onPrint}>
            <i className="fa fa-print" />
          </a>
      </span>
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
